<template>
  <HubSelectField
    :name="name"
    :value="value"
    :label="label"
    class="modal-form__field"
    @change="onSelect"
    :items="items"
    :disabled="disabled"
    :error="isErrored"
    :error-messages="errorMessages"
  />
</template>

<script>
export default {
  name: 'StoreDataDropdown',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
    },
    dataGetter: {
      type: String,
      required: true,
    },
    dataGetterArgument: {
      type: [String, Number],
      default: null,
    },
    dataPath: {
      type: String,
      required: false,
      default: '',
    },
    itemValueKey: {
      type: String,
      required: true,
    },
    itemTextKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
    },
  },
  computed: {
    items() {
      const getterData =
        typeof this.$store.getters[this.dataGetter] === 'function'
          ? this.$store.getters[this.dataGetter](this.dataGetterArgument)
          : this.$store.getters[this.dataGetter]

      const itemData =
        this.dataPath.length > 0
          ? this.dataPath.split('.').reduce((prev, cur) => {
              const childData = prev[cur]
              if (!childData) throw new Error(`Property "${cur}" not found in getter data`)
              return childData
            }, getterData)
          : getterData

      return (itemData ?? []).map(s => ({
        text: s[this.itemTextKey],
        value: s[this.itemValueKey],
      }))
    },
    isErrored() {
      return this.errorMessages?.length > 0
    },
  },
  methods: {
    onSelect(selectedValue) {
      this.$emit('input', selectedValue)
    },
  },
}
</script>
