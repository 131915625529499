<template>
  <v-autocomplete
    multiple
    deletable-chips
    small-chips
    outlined
    :disabled="disabled"
    v-model="selectedRegion"
    name="origin-region"
    @change="onSelect($event)"
    :items="items"
    ref="operating-regions"
    :label="$t('billing.operatingRegions')"
    :error-messages="(errorMessages && errorMessages.operatingRegions) || []"
    :rules="operatingRegionsRule"
    class="operating-regions"
  />
</template>

<script>
export default {
  name: 'OperatingRegionsMultiselect',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Object,
    },
    currentRegion: {
      type: Array,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedRegion: this.currentRegion,
    }
  },
  computed: {
    operatingRegionsRule() {
      return [v => v.length > 0 || this.$t('billing.emptyOriginRegionNotAllowed')]
    },
  },
  methods: {
    onSelect(e) {
      this.selectedRegion = e
      this.$emit('operatingRegion', this.selectedRegion)
      this.$emit('reset')
    },
    clearData() {
      this.selectedRegion = ''
    },
  },
  watch: {
    currentRegion: function(newValue) {
      this.selectedRegion = newValue
    },
  },
}
</script>

<style lang="scss">
.operating-regions {
  &.theme--light.v-input--is-disabled {
    fieldset {
      background-color: var(--v-gr300-base) !important;
    }

    .v-input__slot {
      color: var(--v-disabledText-base);
    }
  }
}
</style>
